@tailwind base;
@tailwind components;
@tailwind utilities;

.privacyBack{
    background-image: url('/public//images/hero-section_1.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}
.termsConditionsBack{
    background-image: url('/public//images/hero-section.webp');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}